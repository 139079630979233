@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.me {
    margin-bottom: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    row-gap: 1.5rem;
    column-gap: 0.5rem;

    .block {
        display: flex;
        aspect-ratio: 2 / 1;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $couleur-neutre-light;
            box-shadow:
                0 10px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px -2px rgba(0, 0, 0, 0.25);
            transform: scale(1.05);
        }

        .image {
            flex: 1;

            a {
                display: block;
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.2rem;

            .index {
                @include fontBold;
                color: $couleur-accent-regular;
                font-size: $font-size-xxl;
                margin: 0 0 10px 0;
                padding: 0;
            }

            .title {
                @include titleH4;
                @include text-truncate(3);
                line-height: 20px;
                padding: 0 0.3rem;
                text-align: center;

                a {
                    color: $couleur-base-noir;
                }

                @media #{$mq-large-and-over} {
                    @include text-truncate(4);
                }

                @media #{$mq-xl-and-over} {
                    @include text-truncate(5);
                }
            }
        }
    }
}