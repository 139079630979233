@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    text-align: center;
    padding-bottom: 2rem;

    .title {
        @media #{$mq-medium-and-over} {
            @include titleH1;
        }
    }
}
