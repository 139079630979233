@import '../../../../styles/variables.scss';

.me {
    .categoryList {
        margin-bottom: 15px;
        overflow: hidden;
        text-align: center;
        width: 100%;

        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-right: 1rem;
                button {
                    min-width: 120px;
                }
            }

            li:last-child {
                margin-right: 0;
            }
        }
    }

    .recettesList {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: $grid-column-gap;
        margin-bottom: 3.5rem;

        @media #{$mq-medium-and-over} {
            grid-template-columns: repeat(2, 1fr);
        }
        @media #{$mq-large-and-over} {
            grid-template-columns: repeat(3, 1fr);
        }

        .block {
            margin-bottom: 0;
            height: 100%;

            :global(.uiRecette) {
                height: 100%;
            }
        }
    }
}
