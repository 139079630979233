@import '../../../../styles/variables.scss';

.me{
    padding-bottom: 50px;

    .block{
        box-sizing: border-box;
        margin-bottom: 0;
        padding-right: 15px;
    }
}

