@import '../../../../styles/variables.scss';

.me{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: $grid-column-gap;
    margin-bottom: 1rem;

    @media #{$mq-medium-and-over} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media #{$mq-large-and-over} {
        grid-template-columns: repeat(3, 1fr);
    }

    .block{
        margin-bottom: 1rem;
    }
}
