@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    padding-bottom: 2rem;
    padding-top: 3rem;

    .title {
        margin: 0 0 25px 0;
        text-align: center;
        border-bottom: 2px solid $couleur-primaire-dark;
        padding-bottom: 10px;

        @media #{$mq-medium-and-over} {
            @include titleH1;
        }
    }

    .buttonLink {
        text-align: center;
    }
}
