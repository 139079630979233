@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.me {
    background-color: $couleur-neutre-light;
    padding-bottom: 2rem;
    padding-top: 2rem;

    .title {
        margin: 0 0 25px 0;
        text-align: center;

        @media #{$mq-medium-and-over} {
            @include titleH1;
        }
    }

    .buttonLink {
        text-align: center;
    }
}
