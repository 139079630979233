@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.me {
    padding-bottom: 50px;

    .block {
        padding-right: 15px;

        &::after {
            clear: both;
            content: "";
            display: table;
        }

        .image {
            a {
                display: block;
                position: relative;
            }
        }

        .content {
            align-content: center;
            align-items: center;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            padding: 10px 0;

            .index {
                color: $couleur-accent-regular;
                font-size: $font-size-xxl;
                font-weight: 800;
                margin: 0;
                min-width: 38px;
                padding: 4px 0 0 0;
                text-align: center;
                width: 38px;
            }

            .title {
                @include titleH4;
                @include text-truncate(2);
                margin: 0;
                padding: 0 10px 0 0;
                text-align: center;

                a {
                    color: $couleur-base-noir;
                }
            }
        }
    }
}
