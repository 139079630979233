@import '../../styles/variables.scss';

.me {
    --swiper-pagination-color: #{$couleur-accent-regular};

    :global(.swiper-pagination) {
        margin-top: 10px;
        position: unset;
    }
}
