@import '../../../../styles/variables.scss';

.me {
    z-index: -2;

    ul {
        margin: 0;

        li {
            background-color: $couleur-neutre-regular;
            list-style: none;
            position: relative;
            margin-bottom: 1rem;
            isolation: isolate;
        }
    }
}
