@import '../../../styles/variables.scss';
@import '../../../styles/mixins.scss';

.meDesktop {
    background-position: center;
    background-size: cover;
    display: block;
    padding-top: 36.016949152542373%;
    position: relative;
    width: 100%;

    .txtZone {
        bottom: 12%;
        position: absolute;
        padding: 2%;
        width: 36%;

        &.showSafeZone {
            background: rgba($color: #000000, $alpha: 0.3);
        }

        .tag {
            display: none;
        }

        h2 {
            margin: 0 0 0.5rem 0;
        }

        p {
            @include text-truncate(3);
            line-height: 1.1rem;
        }

        .cta {
            text-align: center;
        }

        hr {
            display: none;
        }

        &.block {
            background-color: #fff;
            color: #000 !important;
            padding-top: 35px;

            .tag {
                display: inline-block;
            }

            hr {
                background: $couleur-secondaire-regular;
                border: none;
                display: block;
                height: 1px;
                overflow: hidden;
                margin: -5px 0 5px 0;
                width: 50%;
            }

            .cta {
                margin-left: -5px;
                text-align: left;
            }
        }
    }

    &.template_gauche {
        .txtZone {
            left: 5%;
        }
    }

    &.template_droite {
        .txtZone {
            right: 5%;
        }
    }
}
.meMobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .img {
        width: 100%;
    }

    h2 {
        margin: 0.5rem 0;
    }

    .txtZone {
        text-align: center;
    }

    .cta {
        margin: 0.5rem 0;
        text-align: center;
    }
}
