@import '../../../../styles/variables.scss';

.me{
    padding-bottom: 50px;

    .categoryList{
        margin-bottom: 20px;
        text-align: center;

        .tabsList{
            display: inline-block;
            max-width: 240px;
            min-width: 120px;
        }
    }

    .block{
        box-sizing: border-box;
        margin-bottom: 20px;
        padding-right: 15px;
    }
}
