@import '../../../../styles/variables.scss';
@import '../../../../styles/mixins.scss';

.me {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
        color: $couleur-base-noir;
    }

    h3 {
        margin-bottom: 0.5rem;
        margin-top: 2rem;
    }

    .hr {
        border: 1px;
        border-color: $couleur-secondaire-regular;
        border-style: solid;
        margin-bottom: 0.5rem;
    }

    .arrivagesTop {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        isolation: isolate;
        margin-bottom: 1rem;
        position: relative;

        .bgImg {
            height: 100%;
            object-fit: cover;
            position: absolute;
            width: 100%;
            z-index: -1000;
        }

        .arrivageMain {
            background-color: #fff;
            position: relative;
            margin-bottom: 3rem;
            margin-right: 5rem;
            margin-top: 3rem;
            text-align: left;
            width: 30%;

            .tag {
                left: 1.5rem;
            }

            .articleContent {
                padding: 1rem 1.5rem;

                h3 {
                    max-width: 25ch;
                }

                .hr {
                    width: 50%;
                }
            }
        }

        .arrivageList {
            background-color: $couleur-base-blanc;
            display: flex;
            list-style: none;
            margin-bottom: 0.75rem;
            width: 100%;

            li {
                display: flex;
                padding: 0.5rem 0;
                margin: 0;
                width: 100%;

                .leftSide {
                    width: 50%;

                    a {
                        display: block;
                        height: 100%;
                        font-family: 'futura-pt', sans-serif;
                        font-weight: 600;
                        width: 100%;

                        .imgRecette {
                            aspect-ratio: 1 / 1;
                            height: 100%;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }

                .rightSide {
                    padding: 0 0.5rem;
                    width: 50%;

                    .rightSideTitle,
                    p {
                        margin-bottom: 0;
                    }

                    .rightSideTitle {
                        @include text-truncate(5);

                        @media #{$mq-medium-only} {
                            @include text-truncate(3);
                        }
                    }

                    p {
                        font-weight: 500;
                    }

                    @media #{$mq-large-and-over} {
                        padding-top: 2rem;
                    }
                }
            }
        }
    }

    .arrivagesBot {
        isolation: isolate;
        position: relative;

        .bg {
            background-color: $couleur-neutre-regular;
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: -2;

            .bgImg {
                height: 100%;
                width: 60%;
                left: 0;
                object-fit: cover;
                position: absolute;
                z-index: -1;
            }
        }

        .articleMain {
            display: flex;
            justify-content: flex-end;

            .articleCard {
                background-color: #fff;
                margin: 3rem 1rem;
                padding: 1rem;
                text-align: left;
                width: 60%;

                .hr {
                    width: 90%;
                }

                h3 {
                    margin-top: 0;
                }

                p {
                    font-weight: 500;
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }

                .articleAuthor {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    z-index: 1000;

                    .authorContainer {
                        align-items: center;
                        display: flex;

                        .icon {
                            width: 40px;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
