@import '../../styles/variables.scss';

.me {
    margin-bottom: 50px;

    :global(.swiper-button-prev) {
        background-color: #ffffff;
        opacity: 0.5;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
        height: 30px;
        padding: 0.2rem;
        width: 30px;

        &:hover {
            opacity: 1;
        }
    }

    :global(.swiper-button-next) {
        background-color: #fff;
        opacity: 0.5;
        border-radius: 50%;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
        height: 30px;
        padding: 0.2rem;
        width: 30px;

        &:hover {
            opacity: 1;
        }
    }

    :global(.swiper-pagination) {
        margin-top: 20px;
        position: unset;
    }

    :global(.swiper-button-prev::after) {
        font-weight: bold;
        position: absolute;
        left: 30%;
        opacity: 1;
    }

    :global(.swiper-button-next::after) {
        font-weight: bold;
        position: absolute;
        right: 30%;
        opacity: 1;
    }

    :global(.swiper-pagination-bullet) {
        position: relative;
        z-index: 1000;
    }

    --swiper-pagination-bullet-size: 16px;
    --swiper-pagination-color: #{$couleur-accent-regular};
    --swiper-navigation-size: 20px;
    --swiper-navigation-color: #00000;
    --swiper-navigation-sides-offset: 10px;
    --swiper-pagination-bullet-horizontal-gap: 7px;
}
